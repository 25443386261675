import axios from 'axios'
import Vue from 'vue'

const state = {
  purchaseOrder: {},
  purchaseOrders: [],
  purchaseOrdersInReview: [],
  purchaseOrdersInReviewCount: 0,
  purchaseOrdersCompleted: [],
  purchaseOrdersCompletedCount: 0
}

const getters = {
  returnPurchaseOrder: (state) => {
    return state.purchaseOrder
  },
  returnPurchaseOrders: (state) => {
    return state.purchaseOrders
  },
  returnPurchaseOrdersInReview: (state) => {
    return state.purchaseOrdersInReview
  },
  returnPurchaseOrdersInReviewCount: (state) => {
    return state.purchaseOrdersInReviewCount
  },
  returnPurchaseOrdersCompleted: (state) => {
    return state.purchaseOrdersCompleted
  },
  returnPurchaseOrdersCompletedCount: (state) => {
    return state.purchaseOrdersCompletedCount
  },
}


const mutations = {
  changePurchaseOrder: (state, payload) => {
    state.purchaseOrder = payload
  },
  changePurchaseOrders: (state, payload) => {
    state.purchaseOrders = payload
  },
  changePurchaseOrdersInReview: (state, payload) => {
    state.purchaseOrdersInReview = payload
  },
  changePurchaseOrdersInReviewCount: (state, payload) => {
    state.purchaseOrdersInReviewCount = payload
  },
  changePurchaseOrdersCompleted: (state, payload) => {
    state.purchaseOrdersCompleted = payload
  },
  changePurchaseOrdersCompletedCount: (state, payload) => {
    state.purchaseOrdersCompletedCount = payload
  },
  killPurchaseOrder: (state) => {
    state.purchaseOrder = {}
  },
  killPurchaseOrders: (state) => {
    state.purchaseOrders = []
  },
  killPurchaseOrdersInReview: (state) => {
    state.purchaseOrdersInReview = []
  },
  killPurchaseOrdersCompleted: (state) => {
    state.purchaseOrdersCompleted = []
  },
}

const actions = {
  async updatePurchaseOrder({ commit, state }, payload) {
    let response = await axios.put(`/purchase_orders/${payload}`, state.purchaseOrder)
    if(response.status == 200) {
      commit('killPurchaseOrder')
      commit('changePurchaseOrder', response.data.purchase_order)
      return true
    }
    else {
      return false
    }
  },  
  async updateRemotePurchaseOrder({ commit, state }, payload) {
    let response = await axios.put(`/purchase_orders/${payload.id}`, payload)
    if(response.status == 200) {
      // commit('killPurchaseOrder')
      // commit('changePurchaseOrder', response.data.purchase_order)
      return true
    }
    else {
      return false
    }
  },  
  async getPurchaseOrder({ commit }, payload) {
    let response = await axios.get(`/purchase_orders/${payload}`)
    if(response.status == 200) {
      commit('killPurchaseOrder')
      commit('changePurchaseOrder', response.data.purchase_order)
      return true
    }
    else {
      return false
    }
  },
  async getPurchaseOrdersInReview({ commit }, payload) {
    let response = await axios.get(`/mission_control/purchase_orders_in_review`,  {params: payload})
    if(response.status == 200) {
      commit('killPurchaseOrdersInReview')
      response.data.purchase_orders.forEach(element => {
        element.dialog = false
      })
      commit('changePurchaseOrdersInReviewCount', response.data.pagy.count)
      commit('changePurchaseOrdersInReview', response.data.purchase_orders)
      return true
    }
    else {
      return false
    }
  },
  async getPurchaseOrdersCompleted({ commit }, payload) {
    let response = await axios.get(`/mission_control/purchase_orders_completed`,  {params: payload})
    if(response.status == 200) {
      commit('killPurchaseOrdersCompleted')
      response.data.purchase_orders.forEach(element => {
        element.dialog = false
      })
      commit('changePurchaseOrdersCompletedCount', response.data.pagy.count)
      commit('changePurchaseOrdersCompleted', response.data.purchase_orders)
      return true
    }
    else {
      return false
    }
  },
  async getPurchaseOrdersDashboard({ commit }, payload) {
    let response = await axios.get(`/mission_control/purchase_orders_dashboard`,  {params: payload})
    if(response.status == 200) {
      commit('killPurchaseOrdersInReview')
      response.data.purchase_orders.forEach(element => {
        element.dialog = false
      })
      commit('changePurchaseOrdersInReviewCount', response.data.pagy.count)
      commit('changePurchaseOrdersInReview', response.data.purchase_orders)
      return true
    }
    else {
      return false
    }
  },
  async completePurchaseOrder({ commit }, payload) {
    let response = await axios.post(`/purchase_orders/${payload}/complete`)
    if(response.status == 200) {
      return true
    }
    else {
      return false
    }
  },
  async finishedManuallyPurchaseOrder({ commit }, payload) {
    let response = await axios.post(`/purchase_orders/${payload}/finished_manually`)
    return response
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
