
const state = {
  confirm: false,
  message: '',
  icon: ''
}

const getters = {
  returnConfirm: (state) => {
    return state
  },
}

const mutations = {
  showConfirm(state, payload) {
    state.confirm = payload.confirm
    state.message = payload.message
    state.icon = payload.icon
  },
  removeConfirm(state) {
    state.confirm = false
  }
}

const actions = {
  updateConfirm: ({ commit }, payload) => {
    commit('showConfirm', payload)
    setTimeout(() => {
      commit('removeConfirm', { root: true })
    }, 3000)
  },
}

export default {
namespaced: true,
  state,
  getters,
  mutations,
  actions,
}