import Vue from 'vue'
import axios from 'axios'

const state = {
  members: [
  ],
}

const getters = {
  returnInvitedMembers: (state) => {
    return state.members
  },
}

const mutations = {
  addEmptyInvitedMembers: (state, payload) => {
    let maids = Number(payload)
    for(let i=0; i<maids; i++){
      state.members.push({})
    }
  },
  changeInvitedMembers: (state, payload) => {
    state.members.push(payload)
  },
  zeroOutInvitedMembers: (state) => {
    Vue.set(state, 'members', [])
  },
}

const actions = {
  addEmpties: ({ commit }, payload) => {
    commit('addEmptyInvitedMembers', payload)
  },
  updateInvitedMembers: ({ commit }, payload) => {
    commit('changeInvitedMembers', payload)
  },
  async createRemoteMemberships({ state, rootGetters }) {
    let wedding = rootGetters['weddings/returnWedding']
    let id = wedding.id
    let myParams = ""
    let membersToSend = _.reject(state.members, _.isEmpty)
    if(membersToSend.length == 0) {
      return true;
      // The bride email was sent in other call, dont need to send twice 
      // myParams = {weddingMembership: {role: 'bride'}, notifyBride: false}
    }
    else if(membersToSend.length > 1) {
      myParams = {weddingMemberships: membersToSend}
    }
    else {
      myParams = {weddingMembership: membersToSend[0]}
    }
    let response = await axios.post(`/wedding_memberships?weddingId=${id}`, myParams )
    if(response.status == 200) {
      return true
    }
    else {
      return false
    }
  },
  async createRemoteMembershipsFromPayload({ rootGetters }, payload) {
    let wedding = rootGetters['weddings/returnWedding']
    let id = wedding.id
    let myParams = ""
    let membersToSend = _.reject(payload, _.isEmpty)
    myParams = {weddingMemberships: membersToSend}
    let response = await axios.post(`/wedding_memberships?weddingId=${id}`, myParams )
    .catch(error =>{
      let me = error
      debugger
    })
    if(response.status == 200) {
      return true
    }
    else {
      return false
    }
  },
  async createRemoteMembershipsOnManageGuestPage( { rootGetters }, payload) {
    let wedding = rootGetters['weddings/returnWedding']
    let id = wedding.id   
    let response = await axios.post(`/wedding_memberships?weddingId=${id}`, { weddingMembership: payload } )
    if(response.status == 200) {
      return true
    }
    else {
      return false
    }
  },
  resetInvitedMembers: ({ commit }) => {
    commit('zeroOutInvitedMembers')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
