import axios from 'axios'
import Vue from 'vue'

const state = {
  stylists: [],
  stylistCount: null,
  stylistIndex: null,
}

const getters = {
  returnAllStylists: (state) => {
    return state.stylists
  },
  returnStylistCount: (state) => {
    return state.stylistCount
  },
  returnStylistIndex: (state) => {
    return state.stylistIndex
  },
}


const mutations = {
  killStylistIndex: (state, payload) => {
    state.stylistIndex = null
  },
  changeStylistIndex: (state, payload) => {
    state.stylistIndex = payload
  }, 
  changeStylistCount: (state, payload) => {
    state.stylistCount = payload
  },
  changeStylists: (state, payload) => {
    state.stylists.push(payload)
  }, 
  killStylists: (state) => {
    Vue.set(state, 'stylists', [])
  },
}

const actions = {
  setStylistIndex: ({ commit }, payload) => {
    commit('changeStylistIndex', payload)
  },
  updateLocalStylist: ({ commit }, payload) => {
    commit('changeStylists', payload)
  },
  resetStylistIndex: ({ commit }) => {
    commit('killStylistIndex')
  },
  resetStylists: ({ commit }) => {
    commit('killStylists')
  },
  async deactivateStylists({ commit }, payload) {
    let response = await axios.post('/stylists/inactivate', { stylists_ids: payload })
    if(response.status == 200) {
      return true
     }
  },
  async activateStylists({ commit }, payload) {
    let response = await axios.post('/stylists/activate',  { stylists_ids: payload } )
    if(response.status == 200) {
      return true
     }
  },
  async getAllStylists({ commit }, payload) {
    let response = await axios.get(`/stylists`, { params: 
      { 
        items: payload.items,
        page: payload.page,
        filters: {statuses: payload.statuses, roles: payload.roles },
        search: { term: payload.search == undefined ? '' : payload.search.term },
      }
    })
    if(response.status == 200) {
      commit('killStylists')
      commit('changeStylistCount', response.data.pagy.count)
      response.data.stylists.forEach(element => {
        commit('changeStylists', element)
      })
      return true
    }
    else {
      return false
    }
  },
  async getLocationStylists({ commit }, payload) {
    let response = await axios.get(`/stylists`, { params: 
      { 
        locationId: payload.locationId,
        items: payload.items,
        page: payload.page,
        filters: {statuses: payload.statuses, roles: payload.roles },
        search: { term: payload.search == undefined ? '' : payload.search.term },
      }
    })
    if(response.status == 200) {
      commit('killStylists')
      commit('changeStylistCount', response.data.pagy.count)
      response.data.stylists.forEach(element => {
        commit('changeStylists', element)
      })
      return true
    }
    else {
      return false
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
