import Vue from 'vue'
import axios from 'axios'

const state = {
  guests: [
  ],
  weddingGuest: {}
}

const getters = {
  returnWeddingGuests: (state) => {
    return state.guests
  },
  returnWeddingGuest: (state) => {
    return state.weddingGuest
  },
}

const mutations = {
  changeWeddingGuests: (state, payload) => {
    let found = _.findIndex(state.guests, payload)
    if(found == -1) {
      state.guests.push(payload)
    }
  },
  updateWeddingGuests: (state, payload) => {
    state.guests = payload
  },
  updateWeddingGuest: (state, payload) => {
    state.weddingGuest = payload
  },
  zeroOutWeddingGuest: (state) => {
    Vue.set(state, 'weddingGuest', {})
  },
  zeroOutWeddingGuests: (state) => {
    Vue.set(state, 'guests', [])
  },
}

const actions = {
  updateLocalWeddingGuests: ({ commit }, payload) => {
    commit('changeWeddingGuests', payload)
  },
  async updateRemoteWeddingGuests({ commit, rootGetters, state }) {
    state.guests.forEach(element => {
      let response = axios.put(`/users/${element.id}`, { user: element })
      if(response.status == 200){
        commit('zeroOutWeddingGuests')
        let party = []
          response.data.weddingMemberships.forEach(member => {
            if(member.role != 'bride') {
              member.membershipId = member.id
              member.id = member.userId
              delete member.userId
              party.push(member)
            }
          })
          commit('updateWeddingGuests', party)
          return true
      }
    })
  },
  async getRemoteWeddingGuests({ commit, rootGetters }, payload) {
    let weddingId = 0
    if(payload) {
      weddingId = payload.weddingId
    }
    else {
      let wedding = rootGetters['weddings/returnWedding']
      weddingId = wedding.id
    }
    let response = await axios.get(`/wedding_memberships?weddingId=${weddingId}`, { params: { weddingMembership: { } }} )
    if(response.status == 200) {
      commit('zeroOutWeddingGuests')
      let party = []
        response.data.weddingMemberships.forEach(member => {
          if(member.role != 'bride') {
            member.membershipId = member.id
            member.id = member.userId
            delete member.userId
            party.push(member)
          }
        })
        commit('updateWeddingGuests', party)
        return true
      }
      else {
        return false
      }
  },
  resetWeddingGuests: ({ commit }) => {
    commit('zeroOutWeddingGuests')
  },
  resetWeddingGuest: ({ commit }) => {
    commit('zeroOutWeddingGuest')
  },
  async getRemoteWeddingGuest({ commit }, payload) {
    let response = await axios.get(`/users/${payload}`)
    if(response.status == 200) {
      commit('zeroOutWeddingGuest')
      commit('updateWeddingGuest', response.data.user)
      return true
    }
    else {
      return false
    }
  },
  async updateRemoteWeddingGuest({ commit, state }, payload) {
    let me = state.weddingGuest
    let response = await axios.put(`/users/${payload}`, { user: state.weddingGuest })
    if(response.status == 200) {
      commit('zeroOutWeddingGuest')
      commit('updateWeddingGuest', response.data.user)
      return true
    }
    else {
      return false
    }
  },
  async updateRemoteWeddingGuestMembership({ commit }, payload) {
    let response = await axios.put(`/wedding_memberships/${payload.id}`, 
    { weddingId: payload.weddingId, weddingMembership: { id: payload.id, role: payload.role, is_cash_enabled: payload.is_cash_enabled} }
    )
    if(response.status == 200) {
      return true
    }
    else {
      return false
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
