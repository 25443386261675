
const state = {
  errorMessage: ''
}

const getters = {
  returnErrorMessage: (state) => {
    return state.errorMessage
  },
}

const mutations = {
  changeErrorMessage(state, payload) {
    state.errorMessage = payload
  },
  removeErrorMessage(state) {
    state.errorMessage = ''
  },
}

const actions = {
  killErrorMessage: ({ commit }) => {
    commit('removeErrorMessage')
  },
  createErrorMessage: ({ commit }, payload) => {
    commit('changeErrorMessage', payload)
  },
}

export default {
namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
