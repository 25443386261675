import axios from 'axios'
import Vue from 'vue'


const state = {
  user: {},
  contactCount: null,
  users: [],
  userIndex: null,

}

const getters = {
  returnUser: (state) => {
    return state.user
  },
  returnContactCount: (state) => {
    return state.contactCount
  },
  returnUsers: (state) => {
    return state.users
  },
  returnUserIndex: (state) => {
    return state.userIndex
  },
}

const mutations = {
  changeUserIndex: (state, payload) => {
    state.userIndex = payload
  },  
  changeUser: (state, payload) => {
    state.user = payload
  },
  changeUsers: (state, payload) => {
    state.users.push(payload)
  },
  changeContactCount: (state, payload) => {
    state.contactCount = payload
  },
  killUser: (state) => {
    Vue.set(state, 'user', {})
  },
  zeroOutUsers: (state) => {
    Vue.set(state, 'users', [])
  },
}

const actions = {
  $touch () {
    validator.$v.$touch()
  },
  $dirty () {
    validator.$v.$dirty()
  },
  setUserIndex({ state, commit }, payload) {
    let index = _.findIndex(state.users, { id: payload })
    commit('changeUserIndex', index)
  },
  async searchAllCustomers({ commit }, payload) {
    let response = await axios.get('/users', {params: { user: payload }})
    if(response.status == 200) {
      commit('changeContactCount', response.data.pagy.count)
      response.data.users.forEach((element) => {
        commit('changeUsers', element)
      })
      return true
    }
    else {
      return false
      console.log(response)
    }
  },
  async getAllCustomers({ state, commit }, payload) {
    let user = {
      onlyLocation: payload.onlyLocation,
      hideInactive: payload.hideInactive,
      locationId: payload.locationId,
      roles: payload.roles,
      userId: payload.userId,
      search: payload.search
    }
    let response = await axios.get('/users', { params: { user: user, items: payload.items, page: payload.page } })
    if(response.status == 200) {
      commit('changeContactCount', response.data.pagy.count)
      commit('zeroOutUsers')
      response.data.users.forEach((element) => {
        let allRoles = []
        element.weddingMemberships.forEach(roles => {
          if(allRoles.length >= 1) {
            allRoles.push(" " + roles.role)
          }
          else {
            allRoles.push(roles.role)
          }
        })
        element.roles = allRoles
        let find = _.find(state.users, { id: element.id })
        if(find == undefined) {
          commit('changeUsers', element)
        }
      })
      return true
    }
    else {
      return false
    }
  },
  updateLocalUser: ({ commit }, payload) => {
    commit('changeUser', payload)
  },
  async createUser({ state, commit }, authenticity_token) {
    let data = {
      user: state.user,
      'g-recaptcha-response-data': authenticity_token,
      'g-recaptcha-response': ""
    }

    let response = await axios.post('/users', data).catch(error => {
      return "A user with this email already exists, please login instead"
    })
    if(response.status == 200) {
      commit('changeUser', response.data.user)
      return true
    }
  },
  async createUserWithoutCommit({}, payload) {
    let data = {
      user: payload.userData,
      'g-recaptcha-response-data': payload.authenticityToken,
      'g-recaptcha-response': "",
      stay: true
    }

    let response = await axios.post('/users', data).catch(error => {
      return "A user with this email already exists, please login instead"
    })
    if(response.status == 200) {
      return response.data.user
    }
  },
  async getRemoteUser({ state, commit }) {
    let response = await axios.get(`/users/${state.user.id}`)
      if(response.status == 200) {
        commit('changeUser', response.data.user)
        return true
      }
      else {
        console.log(response)
        return false
      }
  },
  async updateRemoteUser({ state, commit }, payload) {
    let user = ''
    let userId = ''
    if(payload) {
      user = payload
      userId = payload.id
    }
    else {
      user = state.user
      userId = state.user.id
    }
    try {
      let response = await axios.put(`/users/${userId}`, {
        user: user
      })
      if(response.status == 200) {
        commit('killUser')
        commit('changeUser', response.data.user)
        return true
      }
    } catch (err) {
      return err.response.data
    }
  },
  async updateUserWithoutCommit({}, payload) {
    let user = payload
    let userId = payload.id

    try {
      let response = await axios.put(`/users/${userId}`, {
        user: user
      })
      if(response.status == 200) {
        return response.data.user
      }
    } catch (err) {
      return err.response.data
    }
  },
  checkIfUserExists: async ({ state, commit }, payload) => {
    let response = await axios.get(`/users/email/?email=${encodeURIComponent(payload)}`)
    if(response.status == 200) {
      return response.data
    } else {
      return false
    }
  },
  resetUser: ({ commit }) => {
    commit('killUser')
  },
  resetUsers: ({ commit }) => {
    commit('zeroOutUsers')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}