import axios from 'axios'
import Vue from 'vue'

const state = {
  locations: [],
  locationCount: null,
  selectedLocation: {}
}

const getters = {
  returnLocations: (state) => {
    return state.locations
  },
  returnLocationCount: (state) => {
    return state.locationCount
  },
  returnSelectedLocation: (state) => {
    return state.selectedLocation
  },
}

const mutations = {
  changeLocations: (state, payload) => {
    state.locations.push(payload)
  },
  zeroOutLocations: (state) => {
    Vue.set(state, 'locations', [])
  },
  zeroOutSelectedLocation: (state) => {
    state.selectedLocation = {}
  },
  changeLocationCount: (state, payload) => {
    state.locationCount = payload
  },
  changeSelectedLocation: (state, payload) => {
    state.selectedLocation = payload
  }
}

const actions = {
  $touch () {
    validator.$v.$touch()
  },
  $dirty () {
    validator.$v.$dirty()
  },
  updateSelectedLocation: ({ commit }, payload) => {
    commit('changeSelectedLocation', payload)
  },
  updateLocalLocation: ({ commit }, payload) => {
    commit('changeLocations', payload)
  },
  async createLocation({ state, commit }) {
    let location = state.selectedLocation
    let response = await axios.post(`/locations`, { 
      location: location 
    })
    if(response.status == 200) {
      return true
    }
    else {
      return false
    }
  },
  async updateRemoteLocation({ state, commit }) {
    let location = state.selectedLocation
    let response = await axios.put(`/locations/${location.id}`, { 
      location: location 
    })
    if(response.status == 200) {
      return true
    }
    else {
      return false
    }
  },
  async disconnectQuickbooksRemoteLocation({ state, commit }) {
    let location = state.selectedLocation
    let response = await axios.post(`/locations/${location.id}/remove_quickbooks`, { 
      location: location 
    })
    if(response.status == 200) {
      return true
    }
    else {
      return false
    }
  },
  async reconnectQuickbooksRemoteLocation({ state, commit }) {
    let location = state.selectedLocation
    let response = await axios.post(`/locations/${location.id}/reconnect_quickbooks`, { 
      location: location 
    })
    if(response.status == 200) {
      return true
    }
    else {
      return false
    }
  },
  async getQuickbooksTaxes({ state, commit }) {
    let location = state.selectedLocation
    let response = await axios.get(`/locations/${location.id}/quickbooks_taxes`)
    if(response.status == 200) {
      return response.data
    }
    else {
      return response
    }
  },
  async getRemoteLocation({ commit }, payload) {
    let response = await axios.get(`/locations/${payload}`)
    if(response.status == 200) {
      commit('zeroOutSelectedLocation')
      commit('changeSelectedLocation', response.data.location)
      return true
    }
    else {
      return false
      console.log("didn't get locations")
    }
  },
  async getRemoteLocations({ commit }, payload) {
    let response = ''
    if(payload) {
      response = await axios.get('/locations', {params: { location: payload }})
    }
    else {
      response = await axios.get('/locations', {params: { location: {all: true, items: -1}} })
    }
    if(response.status == 200) {
      commit('zeroOutLocations')
      commit('changeLocationCount', response.data.pagy.count)
      response.data.locations.forEach(element => {
        commit('changeLocations', element)
      })
      return true
    }
    else {
      return false
      console.log("didn't get locations")
    }
  },
  resetLocations: ({ commit }) => {
    commit('zeroOutLocations')
  },
  resetSelectedLocation: ({ commit }) => {
    commit('zeroOutSelectedLocation')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}