import axios from 'axios'
import Vue from 'vue'

const state = {
  wedding: {},
}

const getters = {
  returnWedding: (state) => {
    return state.wedding
  },
}

const mutations = {
  changeWedding: (state, payload) => {
    state.wedding = payload
  },
  zeroOutWedding: (state) => {
    Vue.set(state, 'wedding', {})
  }
}

const actions = {
  async createRemoteWedding({ commit}, payload ) {
    let response = await axios.post(`/weddings`, { wedding: {
      status: 0,
      user_id:  payload.user_id,
      day: payload.day,
      month: payload.month,
      year: payload.year,
      locationId: payload.locationId,
      name: payload.name,
      stylistId: payload.stylistId,
      status: payload.status } })
    if(response.status == 200) {
      commit('zeroOutWedding')
      commit('changeWedding', response.data.wedding)
      return true
    }
    else {
      return false
    }
  },
  updateLocalWedding: ({ commit }, payload) => {
    commit('changeWedding', payload)
  },
  async getRemoteWedding({ commit }, payload) {
    let response = await axios.get(`/weddings/${payload}`, {params: {wedding: {id: payload}}})
    if(response.status == 200) {
      commit('zeroOutWedding')
      commit('changeWedding', response.data.wedding)
      return true
    }
    else {
      return false
    }
  },
  async updateRemoteWedding({ state, commit }, payload ) {
    let weddingId = null
    if(state.wedding.id) {
      weddingId = state.wedding.id
    }
    else {
      weddingId = payload
    }
    state.wedding.id = weddingId
    let response = await axios.put(`/weddings/${weddingId}`, {wedding: state.wedding })
    if(response.status == 200) {
      commit('zeroOutWedding')
      commit('changeWedding', response.data.wedding)
      return true
    }
    else {
      console.log(response)
      return false
    }
  },
  async updateRemoteWeddingLocation({ state, commit }, payload ) {
    let weddingId = null
    if(state.wedding.id) {
      weddingId = state.wedding.id
    }
    state.wedding.id = weddingId
    let response = await axios.patch(`/weddings/${weddingId}/location`, { location_id: payload.locationId })
    if(response.status == 200) {
      commit('zeroOutWedding')
      commit('changeWedding', response.data.wedding)
      return true
    } else {
      console.log(response)
      return false
    }
  },
  resetWedding: ({ commit }) => {
    commit('zeroOutWedding')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}