import axios from 'axios'
import Vue from 'vue'


const state = {
  discount: [],
  discountIndex: 0,
}

const getters = {
  returnDiscount: (state) => {
    return state.discount
  },
  returnDiscountIndex: (state) => {
    return state.discountIndex
  }
}

const mutations = {
  changeDiscountIndex: (state, payload) => {
    state.discountIndex = payload
  },
  changeDiscount: (state, payload) => {
    let alreadyHere = _.find(state.discount, payload)
    if(alreadyHere == undefined) {
      state.discount.push(payload)
    }
  },
  killDiscount: (state) => {
    Vue.set(state, 'discount', [])
  },
}

const actions = {
  $touch () {
    validator.$v.$touch()
  },
  $dirty () {
    validator.$v.$dirty()
  },
  updateLocalDiscount: ({ commit }, payload) => {
    commit('changeDiscount', payload)
  },
  async createRemoteDiscount({ state, commit }, payload) {
    let response = await axios.post(`/group_discounts`, payload)
    if(response.status == 200 || response.status == 201) {
      commit('changeDiscount', response.data.group_discount)
      return true
    }
    else {
      return false
    }
  },
  async createRemoteBulkDiscount({ state, commit }, payload) {
    let response = await axios.post(`/group_discounts/bulk`, payload)
    if(response.status == 200 || response.status == 201) {
      commit('changeDiscount', response.data.group_discount)
      return true
    }
    else {
      return false
    }
  },
  async getRemoteDiscount({ commit }, payload) {
    let response = await axios.get(`/group_discounts/`, { params: {group_discount: { id: payload }}})
    if(response.status == 200) {
      commit('killDiscount')
      commit('changeDiscount', response.data.groupDiscount)
      return true
    }
    else {
      return false
    }    
  },
  async updateRemoteDiscount({ state }, payload) {
    let response = await axios.patch(`/group_discounts/${payload.discountId}`, payload)
    if(response.status == 201) {
      Vue.set(state.discount, state.discountIndex, response.data.group_discount)
      return true
    }
    else {
      return false
    }   
  },
  resetDiscount: ({ commit }) => {
    commit('killDiscount')
  },
  resetDiscountIndex: ({ state, commit }) => {
    let index = 0
    commit('changeDiscountIndex', index)
  },
  createDiscountIndex: ({ state, commit }, payload) => {
    let index = _.findIndex(state.discount, { dress_id: payload.id })
    index == -1 ? index = 0 : index = index
    commit('changeDiscountIndex', index)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
