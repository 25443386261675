import axios from 'axios'
import Vue from 'vue'

const state = {
  pending_wedding: {},
}

const getters = {
  returnPendingWedding: (state) => {
    return state.pending_wedding
  },
}

const mutations = {
  changePendingWedding: (state, payload) => {
    state.pending_wedding = payload
  },
  zeroOutPendingWedding: (state) => {
    Vue.set(state, 'pending_wedding', {})
  }
}

const actions = {
  async createRemotePendingWedding({ commit }) {
    let response = await axios.post(`/pending_weddings`, { pending_wedding: { status: 0 } })
    if(response.status == 200) {
      commit('zeroOutPendingWedding')
      commit('changePendingWedding', response.data.wedding)
      return true
    }
    else {
      return false  
    }
  },
  updateLocalPendingWedding: ({ commit }, payload) => {
    commit('changePendingWedding', payload)
  },
  async getRemotePendingWedding({ commit }, payload) {
    let response = await axios.get(`/pending_weddings/${payload}`, {params: {pending_wedding: {id: payload}}})
    if(response.status == 200) {
      commit('zeroOutPendingWedding')
      commit('changePendingWedding', response.data.wedding)
      return true
    }
    else {
      return false  
    }
  },
  async updateRemotePendingWedding({ state, commit }, payload ) {
    let weddingId = null
    if(state.pending_wedding.id) {
      weddingId = state.pending_wedding.id
    }
    else {
      weddingId = payload
    }
    state.pending_wedding.id = weddingId
    let response = await axios.put(`/pending_weddings/${weddingId}`, {pending_wedding: state.pending_wedding })
    if(response.status == 200) {
      commit('zeroOutPendingWedding')
      commit('changePendingWedding', response.data.wedding)
      return true
    }
    else {
      console.log(response)
      return false
    }
  },
  resetPendingWedding: ({ commit }) => {
    commit('zeroOutPendingWedding')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
