import axios from 'axios'
import Vue from 'vue'

const state = {
  weddingDresses: [],
  weddingDressDetails: {}
}

const getters = {
  returnWeddingDresses: (state) => {
    return state.weddingDresses
  },
  returnWeddingDressDetails: (state) => {
    return state.weddingDressDetails
  },
}

const mutations = {
  changeWeddingDresses: (state, payload) => {
    state.weddingDresses.push(payload)
  },
  changeWeddingDressDetails: (state, payload) => {
    state.weddingDressDetails = payload
  },
  zeroOutWeddingDressDetails: (state) => {
    Vue.set(state, 'weddingDressDetails', {})
  },
  zeroOutWeddingDresses: (state) => {
    Vue.set(state, 'weddingDresses', [])
  },
}

const actions = {
  $touch () {
    validator.$v.$touch()
  },
  $dirty () {
    validator.$v.$dirty()
  },
  async getAllRemoteWeddingDresses({ commit }, payload) {
    let response = await axios.get(`/wedding_showroom/showroom_dresses`, {params: { weddingId: payload } })
    if(response.status == 200) {
      commit('zeroOutWeddingDresses')
      response.data.showroom_dresses.forEach(element => {
        commit('changeWeddingDresses', element)
      })
      return true
    }
    else {
      return false
    }
  },
  async getRemoteWeddingDressDetails({ commit }, payload) {
    let response = await axios.get(`/wedding_showroom/showroom_dresses/${payload}`)
    if(response.status == 200) {
      commit('zeroOutWeddingDressDetails')
      commit('changeWeddingDressDetails', response.data.showroom_dress)
      return true
    }
    else {
      return false
    }
  },
  resetShowroomDresses: ({ commit }) => {
    commit('zeroOutShowroomDresses')
  },
  resetShowroomDressDetails: ({ commit }) => {
    commit('zeroOutWeddingDressDetails')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
