import axios from 'axios'
import Vue from 'vue'

const state = {
  showroomDresses: [],
  showroomDressCount: null
}

const getters = {
  returnShowroomDresses: (state) => {
    return state.showroomDresses
  },
  returnShowroomDressCount: (state) => {
    return state.showroomDressCount
  },
}

const mutations = {
  changeShowroomDresses: (state, payload) => {
    state.showroomDresses.push(payload)
  },
  changeShowroomDressCount: (state, payload) => {
    state.showroomDressCount = payload
  },
  zeroOutShowroomDresses: (state) => {
    Vue.set(state, 'showroomDresses', [])
  },
}

const actions = {
  $touch () {
    validator.$v.$touch()
  },
  $dirty () {
    validator.$v.$dirty()
  },
  async removeRemoteShowroomDresses({ commit }, payload) {
    let response = await axios.delete(`/showroom_dresses/remove_dress`, { data: payload } )
    if(response.status == 200) {
      return true
    }
    else {
      return false
      console.log(response)
    }
  },
  async updateRemoteShowroomDresses({ commit }, payload) {
    let response = await axios.post(`/showroom_dresses/add_dress`, payload )
    if(response.status == 201) {
      commit('zeroOutShowroomDresses')
      if(response.data.dresses != undefined) {
        response.data.dresses.forEach(element => {
          commit('changeShowroomDresses', element)
        })
      }
      return true
    }
    else {
      return false
      console.log(response)
    }
  },
  async getAllRemoteShowroomDresses({ commit }, payload) {
    let response = await axios.get(`/showroom_dresses`, {params: payload })
    if(response.status == 200) {
      commit('changeShowroomDressCount', response.data.pagy.count)
      commit('zeroOutShowroomDresses')
      response.data.dresses.forEach(element => {
        commit('changeShowroomDresses', element)
      })
      return true
    }
    else {
      return false
      console.log(response)
    }
  },
  async updateSelectedShowroomDress({ commit }, payload) {
    let response = await axios.post(`/showroom_dresses/select_dress`, payload )
    if(response.status == 201) {
      return true
    }
    else {
      return false
    }
  },
  async removeSelectedShowroomDress({ commit }, payload) {
    let response = await axios.post(`/showroom_dresses/deselect_dress`, payload )
    if(response.status == 201) {
      return true
    }
    else {
      return false
    }
  },
  async updateShowroomDressRushFee({ commit }, payload) {
    let response = await axios.put(`/wedding_showroom/showroom_dresses/${payload.showroomDressId}`, payload)
    if(response.status == 200) {
      return true
    }
    else {
      return false
    }
  },
  resetShowroomDresses: ({ commit }) => {
    commit('zeroOutShowroomDresses')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
