import axios from 'axios'
import Vue from 'vue'


const state = {
  dressNotes: [],
  dressNoteIndex: 0,
}

const getters = {
  returnDressNotes: (state) => {
    return state.dressNotes
  },
  returnDressNoteIndex: (state) => {
    return state.dressNoteIndex
  }
}

const mutations = {
  changeDressNoteIndex: (state, payload) => {
    state.dressNoteIndex = payload
  },
  changeDressNote: (state, payload) => {
    let alreadyHere = _.find(state.dressNotes, payload)
    if(alreadyHere == undefined) {
      state.dressNotes.push(payload)
    }
  },
  killDressNote: (state) => {
    Vue.set(state, 'dressNotes', [])
  },
}

const actions = {
  $touch () {
    validator.$v.$touch()
  },
  $dirty () {
    validator.$v.$dirty()
  },
  updateLocalDressNote: ({ commit }, payload) => {
    commit('changeDressNote', payload)
  },
  async createRemoteDressNote({ commit }, payload) {
    let send = {
      weddingId: payload.weddingId,
      dress_note: {
        dress_id: payload.dress_id,
        note: payload.note
      }
    }
    let response = await axios.post(`/dresses_notes`, send)
    if(response.status == 201) {
      commit('changeDressNote', response.data.dress_note)
      return true
    }
    else {
      return false
    }
  },
  async updateRemoteDressNote({ state }, payload) {
    let send = {
      weddingId: payload.weddingId,
      dress_note: {
        note: payload.note
      }
    }
    let response = await axios.patch(`/dresses_notes/${payload.noteId}`, send)
      Vue.set(state.dressNotes, state.dressNoteIndex, response.data.dress_note)
    if(response.status == 201) {
      return true
    }
    else {
      return false
    }
  },
  resetDressNote: ({ commit }) => {
    commit('killDressNote')
  },
  resetDressNoteIndex: ({ state, commit }) => {
    let index = 0
    commit('changeDressNoteIndex', index)
  },
  createDressNoteIndex: ({ state, commit }, payload) => {
    let index = _.findIndex(state.dressNotes, { dress_id: payload.id })
    index == -1 ? index = 0 : index = index
    commit('changeDressNoteIndex', index)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
