import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import token from './modules/token'
import weddings from './modules/weddings'
import pending_weddings from './modules/pending_weddings'
import memberships from './modules/memberships'
import createPersistedState from "vuex-persistedstate"
import images from './modules/images'
import locations from './modules/locations'
import showrooms from './modules/showrooms'
import confirm from './modules/confirm'
import resetToken from './modules/resetToken'
import invitedMembers from './modules/invitedMembers'
import weddingGuests from './modules/weddingGuests'
import employee from './modules/employee'
import locationMembership from './modules/locationMembership'
import orders from './modules/orders'
import contracts from './modules/contracts'
import fedex from './modules/fedex'
import notes from './modules/notes'
import filters from './modules/filters'
import dresses from './modules/dresses'
import showroomDresses from './modules/showroom_dresses'
import groupDiscount from './modules/group_discount'
import dressNotes from './modules/dress_notes'
import designers from './modules/designers'
import stylists from './modules/stylists'
import contributions from './modules/contributions'
import weddingDresses from './modules/wedding_dresses'
import adminOrders from './modules/adminOrders'
import orderNotes from './modules/orderNotes'
import purchaseOrders from './modules/purchaseOrders'
import labels from './modules/labels'
import roles from './modules/roles'
import url from './modules/url'
import socialLoginErrors from './modules/socialLoginErrors'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    adminOrders,
    confirm,
    contracts,
    contributions,
    designers,
    dresses,
    dressNotes,
    employee,
    fedex,
    filters,
    groupDiscount,
    images,
    invitedMembers,
    labels,
    locationMembership,
    locations,
    memberships,
    notes,
    orderNotes,
    orders,
    purchaseOrders,
    resetToken,
    roles,
    showroomDresses,
    showrooms,
    socialLoginErrors,
    stylists,
    token,
    url,
    user,
    weddingDresses,
    weddingGuests,
    weddings,
    pending_weddings,
  },
  plugins: [createPersistedState({ storage: window.localStorage })]
})