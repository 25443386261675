import axios from 'axios'
import Vue from 'vue'

const state = {
  contracts: [],
  contract: {},
  selectedContractIndex: 0,
  weddingContract: {},
  contractSignature: {},
  rejectionNotes: {},
  waitingContracts: [],
  contractsInReviewCount: 0,
  contractsWaitingCount: 0,
}

const getters = {
  returnRejectionNotes: (state) => {
    return state.rejectionNotes
  },
  returnSignature: (state) => {
    return state.contractSignature
  },
  returnWeddingContract: (state) => {
    return state.weddingContract
  },
  returnContracts: (state) => {
    return state.contracts
  },
  returnWaitingContracts: (state) => {
    return state.waitingContracts
  },
  returnContract: (state) => {
    return state.contract
  },
  returnSelectedContractIndex: (state) => {
    return state.selectedContractIndex
  },
  returnContractsInReviewCount: (state) => {
    return state.contractsInReviewCount
  },
  returnContractsWaitingCount: (state) => {
    return state.contractsWaitingCount
  },
}

const mutations = {
  changeRejectionNotes: (state, payload) => {
    state.rejectionNotes = payload
  },
  changeContractSignature: (state, payload) => {
    state.contractSignature = payload
  },
  changeWeddingContract: (state, payload) => {
    state.weddingContract = payload
  },
  changeSelectedContractIndex: (state, payload) => {
    state.selectedContractIndex = payload
  },
  zeroOutSelectedContractIndex: (state) => {
    state.selectedContractIndex = 0
  },
  changeContracts: (state, payload) => {
    state.contracts = payload
  },
  changeWaitingContracts: (state, payload) => {
    state.waitingContracts = payload
  },
  changeContract: (state, payload) => {
    state.contract = payload
  },
  changeContractsInReviewCount: (state, payload) => {
    state.contractsInReviewCount = payload
  },
  changeContractsWaitingCount: (state, payload) => {
    state.contractsWaitingCount = payload
  },
  zeroOutContracts: (state) => {
    state.contracts = []
  },
  zeroOutWaitingContracts: (state) => {
    state.waitingContracts = []
  },
  zeroOutContract: (state) => {
    Vue.set(state, 'contract', {})
  },
  zeroOutWeddingContract: (state) => {
    Vue.set(state, 'weddingContract', {})
  },
}

const actions = {
  async createRemoteRejectionNotes({ state }) {
    let contractId = state.weddingContract.id
    let response = await axios.post(`/contracts/${contractId}/reject`, { contract_rejection: state.rejectionNotes })
    if(response.status == 200) {
      return true
    }
    else {
      return false
    }
  },
  async createRemoteSignature(payload) {
    let response = await axios.post(`/contracts/${contracId}/sign`, payload)
    if(response.status == 200) {
      return true
    }
    else {
      return false
    }
  },
  updateSelectedContractIndex: ({ commit }, payload) => {
    commit('changeSelectedContractIndex', payload)
  },
  resetSelectedContractIndex: ({ commit }) => {
    commit('zeroOutSelectedContractIndex')
  },
  updateLocalContracts: ({ commit }, payload) => {
    commit('changeContracts', payload)
  },
  async updateRemoteContract({ state }, payload) {
    let me = state.contract
    let response = await axios.put(`/contracts/${payload}`, state.contract )
    if(response.status == 200) {
      return true
    }
    else {
      return false
    }
  },
  async getRemoteContractsInReview({ commit }, payload) {
    let response = await axios.get('/mission_control/contracts_in_review',  {params: payload})
    if(response.status == 200) {
      commit('zeroOutContracts')
      response.data.contracts.forEach(element => {
        element.dialog = false
      })
      commit('changeContractsInReviewCount', response.data.pagy.count)
      commit('changeContracts', response.data.contracts)
      return true
    }
    else {
      return false
    }
  },
  async getWaitingContracts({ commit }, payload) {
    let response = await axios.get('/mission_control/contracts_waiting_signature',  {params: payload})
    if(response.status == 200) {
      commit('zeroOutWaitingContracts')
      response.data.contracts.forEach(element => {
        element.dialog = false
      })
      commit('changeContractsWaitingCount', response.data.pagy.count)
      commit('changeWaitingContracts', response.data.contracts)
      return true
    }
    else {
      return false
    }
  },
  async getRemoteContract({ commit, dispatch }, payload) {
    let response = await axios.get(`/contracts_review/${payload}`)
    if(response.status == 200) {
      commit('zeroOutContract')
      commit('changeContract',response.data.contracts)
      return true
    }
    else {
      return false
      console.log("didn't get a contract")
    }
  },
  async getRemoteContractByWedding({ commit, dispatch }, payload) {
    commit('zeroOutWeddingContract')
    let response = await axios.get(`weddings/${payload}/bride_review_contract`)
    if(response.status == 200) {
      if(response.data.error) {
        return false
      }
      else {
        commit('changeWeddingContract', response.data.contract)
        return true
      }
    }
    else {
      return false
    }
  },
  resetContracts: ({ commit }) => {
    commit('zeroOutContracts')
  },
  resetContract: ({ commit }) => {
    commit('zeroOutContract')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}