import axios from 'axios'
import Vue from 'vue'

const state = {
  filters: {
    colors: [],
    styles: [],
    straps_sleeves: [],
    silhouettes: [],
    lengths: [],
    fabrics: [],
    details: [],
    back_styles: [],
    designers_names: []
  }
}

const getters = {
  returnFilters: (state) => {
    return state.filters
  },
}

const mutations = {
  changeFilters(state, payload) {
    state.filters = payload
  },
  zeroOutFilters: (state) => {
    Vue.set(state, 'filters',
      {
        colors: [],
        styles: [],
        straps_sleeves: [],
        silhouettes: [],
        lengths: [],
        fabrics: [],
        details: [],
        back_styles: [],
        designers_names: []
      }
    )
  },
}

const actions = {
  async getFilters({ commit, rootGetters }) {
    const locationId = rootGetters['weddings/returnWedding'].locationId
    let response = await axios.get('/filters', {params: {locationId: locationId}})
    if(response.status == 200) {
        commit('changeFilters', response.data.filters)
      return true
    }
    else {
      return false
    }
  },
  resetFilters: ({ commit }) => {
    commit('zeroOutFilters')
  }
}

export default {
namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
