import axios from 'axios'

const state = {
  order: {},
  orderForBilling: {},
  orderSummary: {},
  orderSummaryName: '',
  orders: [],
  inProgressOrderCount: 0,
}

const getters = {
  returnOrder: (state) => {
    return state.order
  },
  returnOrders: (state) => {
    return state.orders
  },
  returnBillingOrder: (state) => {
    return state.orderForBilling
  },
  returnOrderSummary: (state) => {
    return state.orderSummary
  },
  returnOrderSummaryName: (state) => {
    return state.orderSummaryName
  },
  returnInProgressOrderCount: (state) => {
    return state.inProgressOrderCount
  },
}

const mutations = {
  changeOrder: (state, payload) => {
    state.order = payload
  },
  changeOrders: (state, payload) => {
    state.orders = payload
  },
  changeBillingOrder: (state, payload) => {
    state.orderForBilling = payload
  },
  zeroOutBillingOrder: (state) => {
    state.orderForBilling = {}
  },
  zeroOutOrder: (state) => {
    state.order = {}
  },
  zeroOutOrders: (state) => {
    state.orders = []
  },
  changeOrderSummary: (state, payload) => {
    state.orderSummary = payload
  },
  zeroOutOrderSummary: (state) => {
    state.orderSummary = {}
  },
  zeroOutOrderSummaryName: (state) => {
    state.orderSummaryName = ''
  },
  changeOrderSummaryName: (state, payload) => {
    state.orderSummaryName = payload
  },
  changeInProgressOrderCount: (state, payload) => {
    state.inProgressOrderCount = payload
  },
}

const actions = {
  $touch () {
    validator.$v.$touch()
  },
  $dirty () {
    validator.$v.$dirty()
  },
  updateLocalOrder({ commit }, payload) {
    commit('zeroOutOrder')
    commit('changeOrder', payload)
  },
  async getAllPaidRemoteOrders({ commit }) {
    commit('zeroOutOrder')
    let response = await axios.get(`/orders/${weddingId}`, { params: { paid: true } })
    if(response.status == 200) {
      response.data.orders.forEach(order => {
        commit('changeOrder', order)
      })
      return true
    }
    else {
      return false
    }
  },
  async getRemoteOrderSummary({ commit }, payload) {
    let response = await axios.get(`/showroom_orders/${payload.orderId}/summary`, { params: { wedding_membership_id: payload.wedding_membership_id } })
    if(response.status == 200) {
      commit('zeroOutOrderSummary')
      commit('changeOrderSummary', response.data.order)
      return true
    }
    else {
      return false
    }
  },
  async getRemoteOrder({ commit }, payload) {
    await axios.get(`/wedding_memberships/${payload}/order`)
    .then(response => {
      if(response.data != undefined) {
        commit('zeroOutOrder')
        commit('changeOrder', response.data.order)
      }
    })
    .catch(error => {

    })
    return true
  },
  async updateRemoteOrder({ commit }, payload) {
    let orderId = payload.orderId
    let dresses = payload.orderDress
    let response = await axios.put(`/orders/${orderId}`, {order: { dress_line_items_attributes: dresses }})
    if(response.status == 200) {
      commit('zeroOutOrder')
      commit('changeOrder', response.data.order)
      return true
    }
    else {
      return false
    }
  },
  async prepareForCheckout({ commit }, payload) {
    let response = await axios.post(`/orders/${payload}/prepare_checkout`)
    if(response.status == 200) {
      commit('zeroOutOrder')
      commit('changeOrder', response.data.order)
      return true
    }
    else {
      return false
    }
  },
  async updateOrderMeasurements({ state, commit }, payload) {
    let dresses = []
    state.order.order_checkout_dress_line_items.forEach(element => {
      let order = {
        id: element.id,
        measurements: {
          bust: element.measurements.bust,
          hips: element.measurements.hips,
          size: element.measurements.size,
          waist: element.measurements.waist,
          height: element.measurements.height,
        },
        extra_length_fee: element.extra_length_fee,
        wedding_membership_id: element.wedding_membership_id
      }
      dresses.push(order)
    })
    let response = await axios.put(`/orders/${state.order.id}`, {order: { dress_line_items_attributes: dresses }})
    if(response.status == 200) {
      commit('zeroOutOrder')
      commit('changeOrder', response.data.order)
      return true
    }
    else {
      Rollbar.error("updateOrderMeasurements() error", response)
      return false
    }
  },
  async getBillingOrder({ commit }, payload) {
    let response = await axios.get(`/orders/${payload}/billing_data`)
    if(response.status == 200) {
      commit('zeroOutBillingOrder')
      commit('changeBillingOrder', response.data.order)
      return true
    }
    else {
      return false
    }
  },
  async getInProgressOrders({ commit }, payload) {
    let response = await axios.get('/mission_control/weddings_in_progress', {params: payload })
    if(response.status == 200) {
      commit('zeroOutOrders')
      response.data.weddings.forEach(element => {
        element.dialog = false
      })
      commit('changeInProgressOrderCount', response.data.pagy.count)
      commit('changeOrders', response.data.weddings)
      return true
    }
    else {
      return false
    }
  },
  async updateOrderShippingMethod({ state, commit } ) {
    let data = state.order
    let order = ''
    if(data.shipping_method == 'pickup') {
      order = {
        shipping_method: data.shipping_method,
        shipping_data: {},
        gratuity_amount: data.gratuity_amount ? data.gratuity_amount : 0,
        in_store_visit: data.in_store_visit,
        is_good_experience: data.is_good_experience, 
      }
    }
    else {
      order = {
        shipping_method: data.shipping_method,
        shipping_data: data.shipping_data,
        phone: data.phone,
        gratuity_amount: data.gratuity_amount ? data.gratuity_amount : 0,
        in_store_visit: data.in_store_visit,
        is_good_experience: data.is_good_experience, 
      }
    }
    let response = await axios.put(`/orders/${data.id}`, { order: order } )
    if(response.status == 200) {
      commit('zeroOutOrder')
      commit('changeOrder', response.data.order)
      return true
    }
    else {
      Rollbar.error("updateOrderShippingMethod() error", response)
      return false
    }
  },
  async removeDressFromOrder ({ commit }, payload) {
    let response = await axios.put(`/orders/${payload.id}`, { order: payload } )
    if(response.status == 200) {
      commit('zeroOutOrder')
      commit('changeOrder', response.data.order)
      return true
    }
    else {
      Rollbar.error("removeDressFromOrder() error", response)
      return false
    }
  },
  async regenarateSalesReceipt({ commit }, payload) {
    let response = await axios.post(`/orders/${payload.id}/regenarate_sales_receipt`)
    if(response.status == 200) {
      commit('zeroOutOrderNotes')
      commit('changeOrderNotes', response.data.order)
      return true
    }
    else {
      return false
    } 
  },
  resetBillingOrder: ({ commit }) => {
    commit('zeroOutBillingOrder')
  },
  resetOrder: ({ commit }) => {
    commit('zeroOutOrder')
  },
  resetOrders: ({ commit }) => {
    commit('zeroOutOrders')
  },
  updateOrderSummary: ({ commit }, payload) => {
    commit('zeroOutOrderSummary', payload)
    commit('changeOrderSummary', payload)
  },
  updateOrderSummaryName: ({ commit }, payload) => {
    commit('zeroOutOrderSummaryName', payload)
    commit('changeOrderSummaryName', payload)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
