import axios from 'axios'
import Vue from 'vue'

const state = {
  locationMembership: [],
}

const getters = {
  returnLocationMembership: (state) => {
    return state.locationMembership
  },
}

const mutations = {
  changeLocationMembership: (state, payload) => {
    state.locationMembership.push(payload)
  },
  zeroOutLocationMembership: (state) => {
    state.locationMembership = []
  },
}

const actions = {
  updateLocalLocationMembership: ({ commit }, payload) => {
    commit('changeLocationMembership', payload)
  },
  resetLocalMemberships: ({ commit }) => {
    commit('zeroOutLocationMembership')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
