
const state = {
  token: null
}

const getters = {
  returnToken: (state) => {
    return state.token
  },
}

const mutations = {
  changeToken(state, payload) {
    state.token = payload
  },
}

const actions = {
  createToken: ({ commit }) => {
    let token = $cookies.get('X-BELLA-TOKEN')
    commit('changeToken', token)
  },
  destroyToken: ({ commit }) => {
    window.sessionStorage.clear()
    commit('changeToken', null)
  }
}

export default {
namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
