
const state = {
  resetToken: null
}

const getters = {
  returnToken: (state) => {
    return state.resetToken
  },
}

const mutations = {
  changeToken(state, payload) {
    state.resetToken = payload
  },
  resetToken: (state) => {
    state.resetToken = null
  },
}

const actions = {
  saveToken: ({ commit }, payload) => {
    commit('changeToken', payload)
  },
  killToken:({ commit }) => {
    commit('resetToken')
  }
}

export default {
namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
