
import axios from 'axios'

const state = {
  url: null
}

const getters = {
  returnUrl: (state) => {
    return state.url
  },
}

const mutations = {
  changeUrl(state, payload) {
    state.url = payload
  },
}

const actions = {
  async getUrl({ commit }) {
    let response = await axios.get(`/app_urls`)
    commit('changeUrl', response.data.appUrl)
  },
}

export default {
namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
