import axios from 'axios'
import Vue from 'vue'

const state = {
  dresses: [],
  dressCount: null
}

const getters = {
  returnDresses: (state) => {
    return state.dresses
  },
  returnDressCount: (state) => {
    return state.dressCount
  },
}

const mutations = {
  changeDresses: (state, payload) => {
    state.dresses.push(payload)
  },
  changeDressCount: (state, payload) => {
    state.dressCount = payload
  },
  zeroOutDresses: (state) => {
    Vue.set(state, 'dresses', [])
  },
}

const actions = {
  $touch () {
    validator.$v.$touch()
  },
  $dirty () {
    validator.$v.$dirty()
  },
  async getAllRemoteDresses({ commit }, payload) {
    let response = await axios.get(`/dresses`, {params: payload })
    if(response.status == 200) {
      commit('changeDressCount', response.data.pagy.count)
      commit('zeroOutDresses')
      response.data.dresses.forEach(element => {
        commit('changeDresses', element)
      })
      return true
    }
    else {
      return false
      console.log(response)
    }
  },
  resetDresses: ({ commit }) => {
    commit('zeroOutDresses')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
