import axios from 'axios'
import Vue from 'vue'

const state = {
  fedex: {}
}

const getters = {
  returnFedEx: (state) => {
    return state.fedex
  },
}

const mutations = {
  updateFedEx: (state, payload) => {
    state.fedex = payload
  }
}

const actions = {
  $touch () {
    validator.$v.$touch()
  },
  $dirty () {
    validator.$v.$dirty()
  },
  updateLocalFedEx: ({ commit }, payload) => {
    commit('updateFedEx', payload)
  },
  async createRemoteFedEx({ commit }, payload) {
    let response = await axios.post(`/locations/${payload.locationId}/add_fedex_account`, payload)
    if(response.status == 200) {
      commit('updateFedEx', response.data.fedexCredential)
      return true
    }
    else {
      return false
    }
  },
  async updateRemoteFedEx({ commit }, payload) {
    let response = await axios.get(`/fedex_credentials`)
    if(response.status == 200) {
      commit('updateFedEx', response.data.managers)
      return true
    }
    else {
      return false
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
