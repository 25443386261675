const state = {
  weddingRole: '', // this is Bride, Bridesmaid, Moh or Guest
  userRole: '', //this is either user or employee
  employeeRole: '' // this should be Admin, Manager or Stylist
}

const getters = {
  returnWeddingRole: (state) => {
    return state.weddingRole
  },
  returnUserRole: (state) => {
    return state.userRole
  },
  returnEmployeeRole: (state) => {
    return state.employeeRole
  },
}

const mutations = {
  changeWeddingRole(state, payload) {
    state.weddingRole = payload
  },
  changeUserRole(state, payload) {
    state.userRole = payload
  },
  changeEmployeeRole(state, payload) {
    state.employeeRole = payload
  },
}

const actions = {
  createWeddingRole: ({ commit }, payload) => {
    commit('changeWeddingRole', payload)
  },
  createUserRole: ({ commit }, payload) => {
    commit('changeUserRole', payload)
  },
  createEmployeeRole: ({ commit }, payload) => {
    commit('changeEmployeeRole', payload)
  },
}

export default {
namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
