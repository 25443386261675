import axios from 'axios'

const state = {
  orderNotes: '',
}

const getters = {
  returnOrderNotes: (state) => {
    return state.orderNotes
  },
}

const mutations = {
  changeOrderNotes: (state, payload) => {
    state.orderNotes = payload
  },
  zeroOutOrderNotes: (state, payload) => {
    state.orderNotes = {}
  },
}

const actions = {
  async updateOrderNotes({ commit }, payload) {
    let response = await axios.put(`/orders/${payload.orderId}/update_note`, { order: { notes: payload.orderNotes }})
    if(response.status == 200) {
      commit('zeroOutOrderNotes')
      commit('changeOrderNotes', response.data.order)
      return true
    }
    else {
      return false
    } 
  },
}

export default {
namespaced: true,
  state,
  getters,
  mutations,
  actions,
}