<template>
  <v-app>
    <router-view name="navigation" :key="$route.fullPath"></router-view>
    <router-view class="fill-height" key="main" style="margin-bottom: 100px"></router-view>
    <router-view name="confirm"></router-view>
  </v-app>
</template>

<script>
  import store from './store/store'

  export default {
    data() {
      return {
      }
    },
  }
</script>

<style>
@import './styles/global_styles.css';
</style>
