import axios from 'axios'
import Vue from 'vue'

const state = {
  contributions: [],
}

const getters = {
  returnContributions: (state) => {
    return state.contributions
  },
}

const mutations = {
  changeContributions: (state, payload) => {
    state.contributions.push(payload)
  },
  zeroOutContributions: (state) => {
    Vue.set(state, 'contributions', [])
  }
}

const actions = {
  updateLocalContributions: ({ commit }, payload) => {
    commit('changeContributions', payload)
  },
  async getRemoteContributions ({ commit }, payload) {
    let response = await axios.get('/contributions', {params: {weddingId: payload}})
    if(response.status == 200) {
      commit('zeroOutContributions')
      response.data.contributions.forEach(element => {
        commit('changeContributions', element)
      })
      return true
    }
    else {
      return false
    }
  },
  async updateRemoteContributions({ state, commit }, payload) {
    let response = await axios.post('/contributions', {
      weddingId: payload,
      contributions: state.contributions
    })
    if(response.status == 201) {
      commit('zeroOutContributions')
      response.data.contributions.forEach(element => {
        commit('changeContributions', element)
      })
      return true
    }
    else {
      return false
    }
  },
  resetContributions: ({ commit }) => {
    commit('zeroOutContributions')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
