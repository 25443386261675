import axios from 'axios'
import Vue from 'vue'

const state = {
  labels: [],
  labelStatuses: {},
  labelCount: 0,
}

const getters = {
  returnLabels: (state) => {
    return state.labels
  },
  returnLabelStatuses: (state) => {
    return state.labelStatuses
  },
  returnLabelCount: (state) => {
    return state.labelCount
  },
}


const mutations = {
  changeLabels: (state, payload) => {
    state.labels = payload
  },
  changeLabelCount: (state, payload) => {
    state.labelCount = payload
  },
  killLabels: (state) => {
    state.labels = []
  },
  changeLabelStatuses: (state, payload) => {
    state.labelStatuses = payload
  },
  killLabelStatuses: (state) => {
    state.labelStatuses = {}
  },
}

const actions = {
  async updateLabel({}, payload) {
    let response = await axios.put(`/labels/${payload.id}`, payload)
    if(response.status == 200) {
      return true
    }
    else {
      return false
    }
  },  
  async getLabels({ commit }, payload) {
    let response = await axios.get(`/mission_control/labels`,  { params: payload })
    if(response.status == 200) {
      commit('killLabels')
      response.data.labels.forEach(element => {
        element.dialog = false,
        element.fedexDialog = false
      })
      commit('changeLabelCount', response.data.pagy.count)      
      commit('changeLabels', response.data.labels)
      return true
    }
    else {
      return false
    }
  },
  async getLabelStatuses({ commit }) {
    let response = await axios.get(`/labels/available_statuses`)
    if(response.status == 200) {
      commit('killLabelStatuses')
      commit('changeLabelStatuses', response.data.available_statuses)
      return true
    }
    else {
      return false
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
