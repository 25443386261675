const login = () => import('../components/onboarding/login.vue')
const forgotPassword = () => import('../components/onboarding/forgot_password.vue')
const resetPassword = () => import('../components/onboarding/reset_password.vue')
const setPassword = () => import('../components/onboarding/set_password.vue')
const createVs = () => import('../components/onboarding/create_vs.vue')
const phone = () => import('../components/onboarding/phone.vue')
const weddingDetails = () => import('../components/onboarding/wedding_details.vue')
const invite = () => import('../components/onboarding/invite.vue')
const tell = () => import('../components/onboarding/tell.vue')
const selectWedding = () => import('../components/onboarding/select_wedding.vue')
const socialAuth = () => import('../components/onboarding/social_auth.vue')


const admin = () => import('../components/app/dashboards/admin/admin.vue')

const navigation = () => import('../components/app/navigation.vue')
const confirm = () => import('../components/app/confirm.vue')

const wedding = () => import('../components/app/dashboards/wedding/wedding.vue')

const weddingParty = () => import('../components/app/dashboards/wedding_party/wedding_party.vue')
const manageContributions = () => import('../components/app/dashboards/wedding_party/manage_contributions.vue')

const selectDresses = () => import('../components/app/dashboards/start_ordering/select_dresses.vue')
const shippingInfo = () => import('../components/app/dashboards/start_ordering/shipping_info.vue')
const confirmation = () => import('../components/app/dashboards/start_ordering/confirmation.vue')
const checkout = () => import('../components/app/dashboards/start_ordering/checkout.vue')

const orders = () => import('../components/app/dashboards/orders/orders.vue')
const orderSummary = () => import('../components/app/dashboards/orders/order_summary.vue')
const limitedOrderSummary = () => import('../components/app/dashboards/orders/limited_order_summary.vue')

const orderList = () => import('../components/app/dashboards/admin_orders/order_list.vue')

const help = () => import('../components/app/dashboards/help/help.vue')

const currentDresses = () => import('../components/app/dashboards/showroom/current_dresses.vue')
const manageDresses = () => import('../components/app/dashboards/showroom/manage_dresses.vue')
const showrooms = () => import('../components/app/dashboards/showroom/showrooms.vue')
const showroomDetails = () => import('../components/app/dashboards/showroom/showroom_details.vue')
const manageWedding = () => import('../components/app/dashboards/showroom/manage_wedding.vue')
const manageGuests = () => import('../components/app/dashboards/showroom/manage_guests.vue')
const quickCheckout = () => import('../components/app/dashboards/showroom/quick_checkout.vue')

const editAndSendContract = () => import('../components/app/dashboards/mission_control/edit_and_send_contract.vue')
const missionControl = () => import('../components/app/dashboards/mission_control/mission_control.vue')
const editAndSendPo = () => import('../components/app/dashboards/mission_control/edit_and_send_po.vue')

const customerManager = () => import('../components/app/dashboards/customer_manager/customer_manager.vue')

const locations = () => import('../components/app/dashboards/locations/locations.vue')
const locationInfo = () => import('../components/app/dashboards/locations/location_info.vue')

const contactsList = () => import('../components/app/dashboards/contacts/contacts_list.vue')
const editContact = () => import('../components/app/dashboards/contacts/edit_contact.vue')

const brideContractList = () => import('../components/app/dashboards/final_contract/bride_contract_list.vue')
const brideContractPage = () => import('../components/app/dashboards/final_contract/bride_contract_page.vue')

const stylistsList = () => import('../components/app/dashboards/stylists/stylists_list.vue')
const editStylist = () => import('../components/app/dashboards/stylists/edit_stylist.vue')

const manageDressesList = () => import('../components/app/dashboards/dresses/manage_dresses_list.vue')

const reportingDash = () => import('../components/app/dashboards/reporting/reporting_dash.vue')

const manage = () => import('../components/app/dashboards/account/manage.vue')

const poIntegrations = () => import('../components/app/dashboards/po_integrations/po_integration_dash.vue')

const audits = () => import('../components/app/dashboards/audits/audits.vue')

const appointmentLocations = () => import('../components/app/appointment_locations.vue')

// redirect_to "/#/socialAuth?email=#{user.email}&token=#{token}&provider=#{provider}"

import store from '../store/store'
const localStore = store

export const routes = [
  {
    path: '/login',
    components: {
      default: login,
    },
    name: 'login',
  },
  {
    path: '/socialAuth',
    components: {
      default: socialAuth,
    },
    name: 'socialAuth',
  },
  // redirect_to "/#/socialAuth?email=#{user.email}&token=#{token}&provider=#{provider}"
  {
    path: '/forgotPassword',
    components: {
      default: forgotPassword,
      'confirm': confirm,
    },
    name: 'forgotPassword',
  },
  {
    path: '/resetPassword',
    components: {
      default: resetPassword,
      'confirm': confirm,
    },
    name: 'resetPassword',
    beforeEnter: (to, from, next) => {
      if (Object.keys(to.query).length !== 0) {
        localStore.dispatch('resetToken/saveToken', to.query.resetToken).then(
          localStore.dispatch('user/updateLocalUser', { id: to.query.userId }).then(
            next()
          )
        )
      }
    }
  },
  {
    path: '/setPassword',
    components: {
      default: setPassword,
      'confirm': confirm,
    },
    name: 'setPassword',
    beforeEnter: (to, from, next) => {
      if (Object.keys(to.query).length !== 0) {
        localStore.dispatch('resetToken/saveToken', to.query.resetToken).then(
          localStore.dispatch('user/updateLocalUser', { id: to.query.userId }).then(
            next()
          )
        )
      }
    }
  },
  {
    path: '/createVs',
    components: {
      default: createVs,
    },
    name: 'createVs',
  },
  {
    path: '/phone',
    components: {
      default: phone,
    },
    name: 'phone',
    beforeEnter: (to, from, next) => {
      let userNotDefined = localStore.getters['user/returnUser'].email === undefined
      if (Object.keys(to.query).length !== 0 && userNotDefined) {
        localStore.dispatch('token/createToken').then(
          localStore.dispatch('user/updateLocalUser', { id: to.query.id }).then(
            localStore.dispatch('user/getRemoteUser').then(
              next()
            )
          )
        )
      }
      localStore.dispatch('token/createToken').then(
        next()
      )
    },
  },
  {
    path: '/appointmentLocations',
    components: {
      default: appointmentLocations,
    },
    name: 'appointmentLocations'
  },
  {
    path: '/weddingDetails',
    components: {
      default: weddingDetails,
    },
    name: 'weddingDetails',
  },
  {
    path: '/invite/:num',
    components: {
      default: invite,
    },
    name: 'invite',
    meta: { auth: true },
  },
  {
    path: '/tell',
    components: {
      default: tell,
    },
    name: 'tell',
    meta: { auth: true },
  },
  {
    path: '/wedding',
    components: {
      default: wedding,
      'navigation': navigation,
    },
    name: 'wedding',
    beforeEnter: (to, from, next) => {
      localStore.dispatch('token/createToken').then(
        next()
      )
    },
  },
  {
    path: '/weddingParty',
    components: {
      default: weddingParty,
      'navigation': navigation,
      'confirm': confirm,
    },
    name: 'weddingParty',
    meta: { auth: false },
  },
  {
    path: '/manageContributions',
    components: {
      default: manageContributions,
      'navigation': navigation,
    },
    name: 'manageContributions',
    meta: {
      auth: false,
      breadcrumb: {
        user: [
          { name: 'Wedding Party', link: 'weddingParty' },
          { name: 'Manage Contributions' }
        ],
        admin: [
          { name: 'Showroom Details', link: 'showroomDetails' },
          { name: 'Manage Contributions' }
        ]
      }
    },
  },
  {
    path: '/selectDresses',
    components: {
      default: selectDresses,
      'navigation': navigation,
    },
    name: 'selectDresses',
    meta: {
      auth: false,
      breadcrumb: [
        { name: 'Showroom', link: 'showroomDetails' },
        { name: 'Select Dresses' }
      ]
    },
  },
  {
    path: '/shippingInfo',
    components: {
      default: shippingInfo,
      'navigation': navigation,
    },
    name: 'shippingInfo',
    meta: {
      auth: false,
      breadcrumb: [
        { name: 'Showroom', link: 'showroomDetails' },
        { name: 'shipping Info' }
      ]
    },
  },
  {
    path: '/orders',
    components: {
      default: orders,
      'navigation': navigation,
    },
    name: 'orders',
    meta: { auth: false },
  },
  {
    path: '/orderSummary',
    components: {
      default: orderSummary,
      'navigation': navigation,
    },
    name: 'orderSummary',
    meta: {
      auth: false,
      breadcrumb: {
        admin: [
          { name: 'Order Management', link: 'orderList' },
          { name: 'Order Summary' }
        ],
        user: [
          { name: 'Orders', link: 'orders' },
          { name: 'Order Summary' }
        ]
      }
    },
  },
  {
    path: '/limitedOrderSummary',
    components: {
      default: limitedOrderSummary,
      'navigation': navigation,
    },
    name: 'limitedOrderSummary',
    meta: { auth: false },
  },
  {
    path: '/checkout',
    components: {
      default: checkout,
      'navigation': navigation,
    },
    name: 'checkout',
  },
  {
    path: '/confirmation',
    components: {
      default: confirmation,
      'navigation': navigation,
    },
    name: 'confirmation',
  },
  {
    path: '/help',
    components: {
      default: help,
      'navigation': navigation,
    },
    name: 'help',
  },
  {
    path: '/currentDresses',
    components: {
      default: currentDresses,
      'navigation': navigation,
    },
    name: 'currentDresses',
    meta: {
      breadcrumb: [
        { name: 'Showroom', link: 'showroomDetails' },
        { name: 'Current Dresses' }
      ]
    }
  },
  {
    path: '/manageDresses',
    components: {
      default: manageDresses,
      'navigation': navigation,
    },
    name: 'manageDresses',
  },
  {
    path: '/editAndSendContract',
    components: {
      default: editAndSendContract,
      'navigation': navigation,
    },
    name: 'editAndSendContract',
    meta: {
      breadcrumb: [
        { name: 'Mission Control', link: 'missionControl' },
        { name: 'Edit and Send Contract' }
      ]
    }
  },
  {
    path: '/missionControl',
    components: {
      default: missionControl,
      'navigation': navigation,
    },
    name: 'missionControl',
  },
  {
    path: '/customerManager',
    components: {
      default: customerManager,
      'navigation': navigation,
    },
    name: 'customerManager',
  },
  {
    path: '/editAndSendPo',
    components: {
      default: editAndSendPo,
      'navigation': navigation,
    },
    name: 'editAndSendPo',
    meta: {
      breadcrumb: [
        { name: 'Mission Control', link: 'missionControl' },
        { name: 'Edit and Send Purchase Order' }
      ]
    }
  },
  {
    path: '/showrooms',
    components: {
      default: showrooms,
      'navigation': navigation,
    },
    name: 'showrooms',
  },
  {
    path: '/showroomDetails',
    components: {
      default: showroomDetails,
      'navigation': navigation,
    },
    name: 'showroomDetails',
    meta: {
      breadcrumb: {
        admin: [
          { name: 'Showrooms', link: 'showrooms' },
          { name: 'Showroom Details' }
        ],
      }
    }
  },
  {
    path: '/locations',
    components: {
      default: locations,
      'navigation': navigation,
    },
    name: 'locations',
  },
  {
    path: '/locationInfo',
    components: {
      default: locationInfo,
      'navigation': navigation,
    },
    name: 'locationInfo',
    meta: {
      breadcrumb: [
        { name: 'Location', link: 'locations' },
        { name: 'Location Info' }
      ]
    }
  },
  {
    path: '/navigation',
    components: {
      default: navigation,
    },
    name: 'navigation',
  },
  {
    path: '/contactsList',
    components: {
      default: contactsList,
      'navigation': navigation,
    },
    name: 'contactsList',
  },
  {
    path: '/editContact',
    components: {
      default: editContact,
      'navigation': navigation,
    },
    name: 'editContact',
    meta: {
      breadcrumb: [
        { name: 'Contacts List', link: 'contactsList' },
        { name: 'Edit Contact' }
      ]
    }
  },
  {
    path: '/admin',
    components: {
      default: admin,
      'navigation': navigation,
    },
    name: 'admin',
  },
  {
    path: '/brideContractList',
    components: {
      default: brideContractList,
      'navigation': navigation,
    },
    name: 'brideContractList',
  },
  {
    path: '/brideContractPage',
    components: {
      default: brideContractPage,
      'navigation': navigation,
    },
    name: 'brideContractPage',
  },
  {
    path: '/manageWedding',
    components: {
      default: manageWedding,
      'navigation': navigation,
    },
    name: 'manageWedding',
    meta: {
      breadcrumb: [
        { name: 'Showroom', link: 'showroomDetails' },
        { name: 'Manage Wedding' }
      ]
    }
  },
  {
    path: '/manageGuests',
    components: {
      default: manageGuests,
      'navigation': navigation,
    },
    name: 'manageGuests',
    meta: {
      breadcrumb: [
        { name: 'Showroom', link: 'showroomDetails' },
        { name: 'Manage Guests' }
      ]
    }
  },
  {
    path: '/quickCheckout',
    components: {
      default: quickCheckout,
      'navigation': navigation,
    },
    name: 'quickCheckout',
    meta: {
      breadcrumb: [
        { name: 'Showrooms', link: 'showrooms' },
        { name: 'Single Order' }
      ]
    }
  },
  {
    path: '/stylistsList',
    components: {
      default: stylistsList,
      'navigation': navigation,
    },
    name: 'stylistsList',
  },
  {
    path: '/editStylist',
    components: {
      default: editStylist,
      'navigation': navigation,
    },
    name: 'editStylist',
  },

  {
    path: '/manageDressesList',
    components: {
      default: manageDressesList,
      'navigation': navigation,
    },
    name: 'manageDressesList',
  },
  {
    path: '/reportingDash',
    components: {
      default: reportingDash,
      'navigation': navigation,
    },
    name: 'reportingDash',
  },
  {
    path: '/manage',
    components: {
      default: manage,
      'navigation': navigation,
    },
    name: 'manage',
    meta: {
      breadcrumb: {
        admin: [
          { name: 'Admin', link: 'admin' },
          { name: 'Manage Account' }
        ],
        user: [
          { name: 'Showroom', link: 'showroomDetails' },
          { name: 'Manage Account' }
        ],
      }
    }
  },
  {
    path: '/orderList',
    components: {
      default: orderList,
      'navigation': navigation,
    },
    name: 'orderList',
  },
  {
    path: '/selectWedding',
    components: {
      default: selectWedding,
      'navigation': navigation,
    },
    name: 'selectWedding',
  },
  {
    path: '/poIntegrations',
    components: {
      default: poIntegrations,
      'navigation': navigation,
    },
    name: 'poIntegrations',
    meta: {
      breadcrumb: [
        { name: 'PO Integrations', link: 'poIntegrations' },
        { name: 'PO Integrations' }
      ]
    }
  },
  {
    path: '/audits',
    components: {
      default: audits,
      'navigation': navigation,
    },
    name: 'audits',
    meta: {
      breadcrumb: {
        admin: [
          { name: 'Admin', link: 'admin' },
          { name: 'Audits' }
        ],
      }
    }
  },
  {
    path: '*',
    component: login
  }
]