import axios from 'axios'
import Vue from 'vue'

const state = {
  staffNotes: [],
  stylistNotes: [],
}

const getters = {
  returnStaffNotes: (state) => {
    return state.staffNotes
  },
  returnStylistNotes: (state) => {
    return state.stylistNotes
  },
}

const mutations = {
  changeStylistNotes(state, payload) {
    state.stylistNotes = payload
  },
  changeStaffNotes(state, payload) {
    state.staffNotes = payload
  },
  zeroOutStaffNotes: (state) => {
    Vue.set(state, 'staffNotes', [])
  },
  zeroOutStylistNotes: (state) => {
    Vue.set(state, 'stylistNotes', [])
  },
}

const actions = {
  async getRemoteStaffNotes({ commit, state }, payload) {
    let response = await axios.get(`/notes`, {params: { weddingId: payload.weddingId, kind: "staff" } })
    if(response.status == 200) {
      commit('zeroOutStaffNotes')
      commit('changeStaffNotes', response.data.notes)

      return true
    }
    else {
      return false
    }
  },
  async getRemoteStylistNotes({ commit }, payload) {
    let response = await axios.get(`/notes`, {params: { weddingId: payload.weddingId, kind: "stylist" } })
    if(response.status == 200) {
      commit('zeroOutStylistNotes')
      commit('changeStylistNotes', response.data.notes[0])
      return true
    }
    else {
      return false
    }
  },
  async updateRemoteNotes({ state }, payload) {
    let note = ''
    let note_id = ''
    if(payload == 'staff') {
      note = {
        weddingId: state.staffNotes[0].wedding_id,
        note: state.staffNotes[0]
      }
      note_id = note.note.id
    }
    else {
      note = {
        weddingId: state.stylistNotes.wedding_id,
        note: state.stylistNotes
      }
      note_id = state.stylistNotes.id
    }
    let response = await axios.patch(`/notes/${note_id}`, note)
    if(response.status == 201) {
      return true
    }
  },
  async createRemoteNotes({ commit, state }) {
    let note = {
      weddingId: state.staffNotes[0].wedding_id,
      note: state.staffNotes[0]
    }
    let response = await axios.post(`/notes`, note)
    if(response.status == 201) {
      commit('zeroOutStaffNotes')
      state.staffNotes.push(response.data.note)
      return true
    }
  },
  resetStaffNotes: ({ commit }) => {
    commit('zeroOutStaffNotes')
  },
  resetStylistNotes: ({ commit }) => {
    commit('zeroOutStylistNotes')
  },
}

export default {
namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
