import axios from 'axios'
import Vue from 'vue'

const state = {
  images: [],
}

const getters = {
  returnImages: state => {
    return state.images;
  },
}

const mutations = {
  changeImages: (state, payload) => {
    state.images.push(payload)
  },
  deleteImage: (state, payload) => {
    let index = state.images.findIndex(image => image.id == payload.id)
    if (index !== -1) {
      state.images.splice(index, 1);
    }
  },
  zeroOutImages: (state) => {
    Vue.set(state, 'images', [])
  }
}

const actions = {
  async getImages({ commit, rootGetters }, payload) {
    let weddingId = 0
    if(payload) {
      weddingId = payload.weddingId
    }
    else {
      weddingId = rootGetters['weddings/returnWedding'].id
    }
    let response = await axios.get('/images', { params: { weddingId }})
    if(response.status == 200) {
      commit('zeroOutImages')
      response.data.images.forEach(element => {
        element.weddingId = weddingId
        commit('changeImages', element)
      })
      return true
    }
    else {
      return false
    }
  },
  removeImage: ({ commit }, { id })=> {
    axios.delete(`/images/${id}`).then(response => {
      commit('deleteImage', { id })
    })
  },
  resetImages: ({ commit }) => {
    commit('zeroOutImages')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
