import axios from 'axios'
import Vue from 'vue'

const state = {
  memberships: [],
  membership: {},
}

const getters = {
  returnMembership: (state) => {
    return state.membership
  },
  returnMemberships: (state) => {
    return state.memberships
  },
}

const mutations = {
  changeMembership: (state, payload) => {
    state.membership = payload
  },
  zeroOutMember: (state) => {
    state.membership = {}
  },
  changeMemberships: (state, payload) => {
    state.memberships = payload
  },
  zeroOutMembers: (state) => {
    state.memberships = []
  },
}

const actions = {
  updateLocalMemberships: ({ commit }, payload) => {
    commit('changeMemberships', payload)
  },
  updateLocalMembership: ({ commit }, payload) => {
    commit('changeMembership', payload)
  },
  async updateRemoteMembership({ state, commit }) {
    let membershipId = state.membership.id
    let weddingId = state.membership.wedding.id
    let response = await axios.put(`/wedding_memberships/${membershipId}?weddingId=${weddingId}`, {
      weddingMembership: state.membership
    })
    if(response.status == 200) {
      commit('zeroOutMember')
      commit('changeMembership', response.data.weddingMembership)
      return true
    }
    else {
      return false
    }
  },
  resetMember: ({ commit }) => {
    commit('zeroOutMember')
  },
  resetMembers: ({ commit }) => {
    commit('zeroOutMembers')
  },
  async getRemoteMembership({ commit }, payload){
    let response = await axios.get(`/wedding_memberships/${payload.membershipId}?weddingId=${payload.weddingId}`, {params: { weddingMembership: {id: payload.membershipId} }})
    if(response.status == 200) {
      commit('zeroOutMember')
      commit('changeMembership', response.data.weddingMembership)
      return true
    }
    else {
      return false
    }
  },
  deleteRemoteMembership: ({ state, commit }) => {
    let membershipId = state.memberships[0].id
    let weddingId = state.memberships[0].weddingId
    let weddingMembership = {
      weddingMembership: {
        id: membershipId
      },
      deleteAll: true
    }
    axios.delete(`/wedding_memberships/${membershipId}?weddingId=${weddingId}?deleteAll=true`, { data: weddingMembership }).then(
      commit('zeroOutMembers')
      )
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
