import axios from 'axios'
import Vue from 'vue'

const state = {
  showrooms: [],
  showroomLength: 0
}

const getters = {
  returnShowrooms: (state) => {
    return state.showrooms
  },
  returnShowroomsLength: (state) => {
    return state.showroomLength
  },
}

const mutations = {
  changeShowroom: (state, payload) => {
    let found = _.find(state.showrooms, payload)
    if(found == undefined) {
      state.showrooms.push(payload)
    }
  },
  zeroOutShowrooms: (state) => {
    Vue.set(state, 'showrooms', [])
  },
  changeShowrooms: (state, payload) => {
    state.showrooms = payload
  },
  changeShowroomsLength: (state, payload) => {
    state.showroomLength = payload
  }
}
const actions = {
  updateLocalShowroom: ({ commit }, payload) => {
    commit('changeShowroom', payload)
  },
  updateRemoteShowroom: ({ state, commit }, payload) => {
    // I'll get to this later..
    const user = state.user
    axios.put(`/users/${user.id}`, {
      user: user
    })
      .then(response => {
        commit('changeUser', response.data.user)
      })
      .catch(errors => {
        console.log(errors)
      })
  },
  async getRemoteShowrooms({ commit }, payload) {
    let response = await axios.get('/wedding_showroom/weddings', { params: payload } )
    if(response.status == 200) {
      commit('zeroOutShowrooms')
      commit('changeShowrooms', response.data.weddings)
      commit('changeShowroomsLength', response.data.pagy.count)
      return true
    }
    else {
      return false
    }
  },
  async deleteRemoteShowroom({ state, commit }, id) {
    let response = await axios.delete(`/showrooms/${id}`)
    return response.data
  },
  resetShowrooms: ({ commit }) => {
    commit('zeroOutShowrooms')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
