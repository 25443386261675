import axios from 'axios'
import Vue from 'vue'

const state = {
  employee: {},
  allManagers: [],
  allStylists: [],
}

const getters = {
  returnEmployee: (state) => {
    return state.employee
  },
  returnAllManagers: (state) => {
    return state.allManagers
  },
  returnAllStylists: (state) => {
    return state.allStylists
  },
}


const mutations = {
  changeEmployee: (state, payload) => {
    state.employee = payload
  },    
  killEmployee: (state) => {
    Vue.set(state, 'employee', {})
  },
  updateAllManagers: (state, payload) => {
    state.allManagers = payload
  },
  updateAllStylists: (state, payload) => {
    state.allStylists = payload
  }
}

const actions = {
  $touch () {
    validator.$v.$touch()
  },
  $dirty () {
    validator.$v.$dirty()
  },
  updateLocalEmployee: ({ commit }, payload) => {
    commit('changeEmployee', payload)
  },
  resetEmployee: ({ commit }) => {
    commit('killEmployee')
  },
  async getAllManagers({ commit }, payload) {
    let response = await axios.get(`/managers`)
    if(response.status == 200) {
      commit('updateAllManagers', response.data.managers)
      return true
    }
    else {
      return false
    }
  },
  async getAllStylists({ commit }, payload) {
    let response = await axios.get(`/stylists`, {params: {location: {locationId: payload}}})
    if(response.status == 200) {
      commit('updateAllStylists', response.data.stylists)
      return true
    }
    else {
      return false
    }
  },
  async updateRemoteEmployee({ commit, rootGetters, state }) {
    let employee = state.employee
    employee.role = rootGetters['roles/returnEmployeeRole'] + 'Membership'
    debugger
    let response = await axios.put(`/stylists/${employee.locationMembership.id}`, employee)
    if(response.status == 200) {
      commit('changeEmployee', response.data.stylist)
      return true
    }
    else {
      return false
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
