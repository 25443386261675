import axios from 'axios'

const state = {
  adminOrders: [],
  adminOrderCount: 0
}

const getters = {
  returnAdminOrders: (state) => {
    return state.adminOrders
  },
  returnAdminOrdersCount: (state) => {
    return state.adminOrderCount
  },
}

const mutations = {
  changeAdminOrders: (state, payload) => {
    state.adminOrders = payload
  },
  zeroOutAdminOrders: (state, payload) => {
    state.adminOrders = []
  },
  changeAdminOrdersCount: (state, payload) => {
    state.adminOrderCount = payload
  },
  zeroOutAdminOrdersCount: (state, payload) => {
    state.adminOrderCount = 0
  },
}

const actions = {
  async getAdminOrders({ commit }, payload) {
    let response = await axios.get(`/showroom_orders/`, {params: payload })
    if(response.status == 200) {
      commit('zeroOutAdminOrders')
      commit('zeroOutAdminOrdersCount')
      commit('changeAdminOrders', response.data.orders)
      commit('changeAdminOrdersCount', response.data.pagy.count)
      return true
    }
    else {
      return false
    } 
  },
}

export default {
namespaced: true,
  state,
  getters,
  mutations,
  actions,
}