import axios from 'axios'
import Vue from 'vue'

const state = {
  designers: []
}

const getters = {
  returnDesigners: (state) => {
    return state.designers
  },
}

const mutations = {
  changeDesigners(state, payload) {
    state.designers = payload
  },
  zeroOutDesigners: (state) => {
    Vue.set(state, 'designers', [])
  },
}

const actions = {
  async getLocationDesigners({ commit, rootGetters }) {
    const locationId = rootGetters['locations/returnSelectedLocation'].id
    let response = await axios.get(`/designers/`, {params: { locationId: locationId }})
    if(response.status == 200) {
      commit('zeroOutDesigners')
        commit('changeDesigners', [...state.designers, ...response.data.designers])
      return true
    }
    else {
      return false
    }
  },
  async getAllDesigners({ state, commit }) {
    let response = await axios.get('/designers')
    if(response.status == 200) {
      commit('zeroOutDesigners')
      commit('changeDesigners', [...state.designers, ...response.data.designers])
      return true
    }
    else {
      return false
    }
  },
  resetDesigners: ({ commit }) => {
    commit('zeroOutDesigners')
  }
}

export default {
namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
